import React, { useState } from 'react';
import useFetch from 'use-http'
import { useGlobalState } from 'shared/context/global-context'
import ProcessButton from 'shared/process-button'
import ErrorNotification from 'shared/error-notification'



const Form = ({ auth, sections, token, closeModal, setView }) => {

	const { post, response, loading, cache } = useFetch()

	const { state, handleError, clearError, hasFieldMessage } = useGlobalState();

	const [formData, setFormData] = useState({
		email: auth ? auth.email : '',
		name: auth ? auth.name : '',
		phone: '',
		company: '',
		website: '',
		sections: [],
		product_count: '',
		description: ''
	});

	const [ terms, setTerms ] = useState( false )


	const handleSubmit = async ( e ) => {

		e.preventDefault();

		cache.clear();

		await post( '/api/v1/listing/enquire', { enquiry: formData } )

		if (response.ok) {
			setView('success')
		} else {
			handleError( response.data );
		}

	};

	const handleSection = ( e ) => {

		let sections = formData.sections
		const value = parseInt( e.value )

		if( e.checked && !sections.includes( value ) ) {
			sections.push( parseInt(e.value) )
		} else {
			sections = sections.filter(item => item !== value)
		}
		setFormData({...formData, sections: sections})
	}

	const hasError = () => {

	}

	return (
			<>

				<ErrorNotification
					error={state.error}
					clearError={clearError}
				/>

				<form onSubmit={handleSubmit}>
				<div className="grid-x grid-padding-x">
					<div className="cell large-12">
						<label>
							<span className="form-label">Your personal email</span>
							<input
								className={hasError("email") ? "input_error" : ""}
								value={formData.email}
								type="email"
								name="email"
								placeholder="your-email@gmail.com"
								onChange={ e => setFormData({...formData, email: e.target.value})}
								disabled={auth}
							/>
							{hasFieldMessage('email')}
						</label>
					</div>

					<div className="cell large-6">
						<label>
							<span className="form-label">Full name</span>
							<input
								className={hasError("name") || hasError("firstname") || hasError("lastname") ? "input_error" : ""}
								value={formData.name}
								type="text"
								name="name"
								placeholder="Your name"
								onChange={ e => setFormData({...formData, name: e.target.value })}
								disabled={auth}
							/>
							{hasFieldMessage('name')}
							{hasFieldMessage('firstname')}
							{hasFieldMessage('lastname')}
						</label>
					</div>

					<div className="cell large-6">
						<label>
							<span className="form-label">Company name</span>
							<input
								className={hasError("company") ? "input_error" : ""}
								value={formData.company}
								type="text"
								name="company"
								placeholder="Company name"
								onChange={ e => setFormData({...formData, company: e.target.value })}
							/>
							{hasFieldMessage('company')}
						</label>
					</div>

					<div className="cell large-6">
						<label>
							<span className="form-label">Work phone</span>
							<input
								className={hasError("phone") ? "input_error" : ""}
								value={formData.phone}
								type="text"
								name="phone"
								placeholder="Mobile or landline"
								onChange={ e => setFormData({...formData, phone: e.target.value })}
							/>
							{hasFieldMessage('phone')}
						</label>
					</div>

					<div className="cell large-6">
						<label>
							<span className="form-label">Website</span>
							<input
								className={hasError("website") ? "input_error" : ""}
								value={formData.website}
								type="text"
								name="website"
								placeholder="https://www.your-website.com"
								onChange={ e => setFormData({...formData, website: e.target.value })}
							/>
							{hasFieldMessage('website')}
						</label>
					</div>

					<div className="cell large-12">
						<label>
						 <span>Key occasions:</span>
						 </label>
						 <div className="checkbox-container">
						 {sections.map((section, index) =>
							 <div className="checkbox" key={`section-${section.id}`}>
							 		<label>
							 			<input
											className={hasError("sections") ? "input_error" : ""}
											checked={formData.sections.includes(section.id)}
											type="checkbox"
											name="sections"
											value={section.id}
											onChange={ e => handleSection( e.target ) }
										/>
							 			{section.name}
							 		</label>
								</div>
						 )}
						 </div>
						 {hasFieldMessage('sections')}
					 </div>

					<div className="cell large-12">
						<label>
							<span className="form-label">Approx. how many experiences are you looking to list</span>
							<input
								className={hasError("product_count") ? "input_error" : ""}
								value={formData.product_count}
								type="text"
								name="product_count"
								placeholder="e.g approximately 10-15"
								onChange={ e => setFormData({...formData, product_count: e.target.value })}
							/>
							{hasFieldMessage('product_count')}
						</label>
					</div>

					<div className="cell large-12">
						<label>
							<span className="form-label">Please give a brief overview of your experiences</span>
							<textarea
								className={hasError("description") ? "input_error" : ""}
								value={formData.description}
								name="description"
								onChange={ e => setFormData({...formData, description: e.target.value })}
							></textarea>
							{hasFieldMessage('description')}
						</label>
					</div>

					<div className="cell large-12">
						<div className="checkbox-container single">
							<div className="checkbox">
								<label>
									<input
										className={hasError("terms") ? "input_error" : ""}
										checked={terms}
										type="checkbox"
										name="terms"
										onChange={e => setTerms( !terms )}
									/>
									By continuing you agree to be contacted by Book a Party regarding your experience by email or phone and have read and understood the <a href="/privacy">Privacy Notice</a>
								</label>
							</div>
						</div>
					</div>

					<ProcessButton
						buttonClass="button primary"
						buttonType="submit"
						process={loading}
					>Register</ProcessButton>
				</div>
			</form>
		</>

	)
}

export default Form
