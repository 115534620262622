import React, { useState, useEffect } from "react";
import Product from "./product";
import useSessionStorage from "shared/hooks/session-storage"
import OrderSwitcher from './filters/order'
import Advert from 'shared/advert.jsx'

const List = React.forwardRef(({ layout, config, results, order, changeOrder }, ref ) => {

	const [ visibleProducts, setVisibleProducts ] = useSessionStorage( config.path, config.loadMore.active ? parseInt(config.loadMore.perPage) : 0 );
	
	const renderProducts = () => {
		
		let scrollToProductNumber = 12
		
		useEffect(() => {
			const { innerWidth: width, innerHeight: height } = window
			scrollToProductNumber = innerWidth <= 630 ? 8 : 12
		}, []);
		
		if( config.loadMore.active ) {
			
			return results.products.slice(0, visibleProducts).map((product,index) => (
				<Product
					key={product.id}
					product={product}
					cdnPath={config.cdnPath}
					path={config.path}
					promoted={config.allowPromoted}
					formValues={config.formConfig.defaultValues ? { pax: config.formConfig.defaultValues.pax, date: config.formConfig.defaultValues.date } : false}
					scrollTarget={config.loadMore.perPage <= visibleProducts && index == scrollToProductNumber ? true : false}
					ref={ref}
				/>
			));
		}

		return results.products.map(product => (
			<Product
				key={product.id}
				product={product}
				cdnPath={config.cdnPath}
				path={config.path}
				promoted={config.allowPromoted}
				formValues={config.formConfig.defaultValues ? { pax: config.formConfig.defaultValues.pax, date: config.formConfig.defaultValues.date } : false}
				scrollTarget={index == scrollToProductNumber ? true : false}
				ref={ref}
			/>
		));
	};
	
	return (
		<div>
			<div className="grid-x grid-padding-x">
				<div className="small-12 large-6 cell">
					<h4>{results.products.length} activities found in {config.title}</h4>
				</div>
				<div className="small-6 cell show-for-large">
					<div className="align-content horizontal right">
						<OrderSwitcher
							order={order}
							changeOrder={changeOrder} 
						/>
					</div>
				</div>
			</div>
					
			<div className={`grid-x grid-padding-x small-up-1 medium-up-2 large-up-3 xlarge-up-4 product-grid ${layout}-view`}>
				{renderProducts()}
			</div>

			{config.loadMore.active && visibleProducts < results.products.length && (
				<div className="grid-x grid-padding-x align-center">
					<div className="cell medium-6 text-center">
						<button
							onClick={() => {
								setVisibleProducts( parseInt( visibleProducts + config.loadMore.perPage ) );
							}}
							type="button"
							className="load-more"
						>
							Load more
						</button>
					</div>
				</div>
			)}
			{config.advert ? (
				<div className="hide-for-large mg-v-lrg">
					<Advert />
				</div>
			) : null}
		</div>
	);
})

export default List;
