import React from 'react'
import ReactModal from 'react-modal';
import axios from 'axios';
import Loader from 'shared/loader'

ReactModal.setAppElement('body');


const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  content : {
  	padding				  : '1rem',
	width				  : 'auto',
	maxWidth			  : '800px',
	height				  : 'auto',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};


class Terms extends React.Component {

	constructor(props) {
		super(props);

		this.state = {
			terms: '',
			agreed: true,
			showModal: false,
			loading: false
		};

		this.handleCloseModal = this.handleCloseModal.bind(this);
	}

	handleCloseModal = () => {
		this.setState({ showModal: false, terms: null });
	}

	handleChange = ( event ) => {
		this.setState({ agreed: !this.state.agreed }, function() {
			this.props.setTerms( this.state.agreed );
		});
	}

	openTerms = (type, event) => {

		event.preventDefault();

		let url = null;

		switch( type ) {
			case 'supplier':
				url = '/suppliers/' + this.props.supplier.id + '/terms'
				break;
			case 'deposits':
				url = '/depositinfo?id=' + this.props.booking.id
				break;
			case 'multipay':
				url = '/multipayinfo?id=' + this.props.booking.id
				break;
			default:
				url = '/terms?modal=1'
		}

		this.setState({ showModal: true });

		if( !this.state.loading ) {
			this.setState({ loading: true });
			axios.get( url ).then(response => {
				this.setState({
					terms: response.data,
					loading: false,
				});
			}).catch(err => {
				console.log(err);
			});
		}
	}

	render() {
		return (
			<div className="small-12 cell terms">
				<p>
					<label className="flex items-top">
						<input type="checkbox" className="mt-5" id="terms-agree" name="payment[accept]" value="1" checked={this.state.agreed} onChange={this.handleChange} />
						<span>By making this {this.props.type == 'voucher' ? `booking` : `payment`} you agree to <a onClick={(event) => this.openTerms( 'supplier', event )}>{this.props.supplier.name}</a> and the <a onClick={(event) => this.openTerms( 'general', event )}>Book a Party</a> terms and conditions</span>
					</label>
				</p>
				<ReactModal
					isOpen={this.state.showModal}
					contentLabel="Terms and conditions"
					style={ModalStyles}
				>
					<button onClick={this.handleCloseModal} className="close-button clearfix" aria-label="Close modal"><span aria-hidden="true">×</span></button>
					{this.state.loading? <Loader /> : null }
					<div dangerouslySetInnerHTML={{__html: this.state.terms}} style={{height: '400px', overflow: 'scroll'}}></div>
				</ReactModal>
			</div>
		)
	}
}

export default Terms;
