import React from 'react'

const Advert = () => {

	return (
		<div className="grid-x grid-padding-x">
			<div className="cell medium-12">
				<div className="border thick red rounded pd">
					<div className="align-content vertical top mg-b">
						<img src="/images/svg/xmastag2.svg" className="mg-r" width="50" alt="bauble image"/>
						<h4 className="no-mg-b">Fantastic value for money <span className="red">guaranteed!</span></h4>
					</div>
					<p className="text-black">When you book with Book a Party, you are booking directly with the party host. This means:</p>
					<ul className="list plain">
						<li className="align-content vertical base">
							<span className="icon icon-star-full small red mg-r-sm"></span>
							<p className="text-black">No booking fees</p>
						</li>
						<li className="align-content vertical base">
							<span className="icon icon-star-full small red mg-r-sm"></span> 
							<p className="text-black">No admin fees</p>
						</li>
						<li className="align-content vertical base">
							<span className="icon icon-star-full small red mg-r-sm"></span>
							<p className="text-black">99% of our experiences are sold at cost</p>
						</li>
						<li className="align-content vertical base">
							<span className="icon icon-star-full small red mg-r-sm"></span>
							<p className="text-black">Prices to suit all budgets</p>
						</li>
					</ul>
				</div>
			</div>
			<div className="cell medium-12">
				<h4 className="mg-t-lrg">What people are saying about our Christmas Parties</h4>
				<div className="border-l thick pd-l">
					<div className="mg-b-sm"><span className="icon icon-star-full mg-r-xsm"></span><span className="icon icon-star-full mg-r-xsm"></span><span className="icon icon-star-full mg-r-xsm"></span><span className="icon icon-star-full mg-r-xsm"></span><span className="icon icon-star-full"></span></div>
					<p>Our joiner Christmas Party was great and the booking process was easy. The decoration in the hotel was festive and classy, staff were friendly, music was great and the food was delicious! Highly recommendable - we’ll definitely do it all again next year!</p>
					<small>- Book a Party customer</small>
				</div>
			</div>
		</div>
	)
}

export default Advert