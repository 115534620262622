import React from 'react';
import {CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import Terms from './terms'

const CardForm = ({ shortForm, formData, handleFormChange, handleCardChange, terms, setTerms }) => {
	return (
		<>
			<CardHeader />

			<div className="grid-x grid-margin-x form-cells">
			
				{!shortForm ? (
				<>
				<div className="small-12 medium-4 cell">
					<label className="form-label" htmlFor="cardFirstname">Card Holder First Name</label>
					<input placeholder="First name" className="form-input" defaultValue={formData.cardFirstname.value} name="cardFirstname" id="cardFirstname" type="text" onChange={handleFormChange} />
				</div>
				<div className="small-12 medium-4 cell">
					<label className="form-label" htmlFor="cardLastname">Card Holder Last Name</label>
					<input placeholder="Last name" className="form-input" defaultValue={formData.cardLastname.value} name="cardLastname" id="cardLastname" type="text" onChange={handleFormChange} />
				</div>
				<div className="small-12 medium-4 cell">
					<label className="form-label" htmlFor="cardPostcode">Card Holder Postcode</label>
					<input placeholder="Postcode" className="form-input" defaultValue={formData.cardPostcode.value} name="cardPostcode" id="cardPostcode" type="text" onChange={handleFormChange} />
				</div>
				</>
				) : null}

				<div className="small-12 medium-6 cell">
					<label className="form-label">Card number</label>
					<div className="form-input" id="cardNumber-wrapper">
						<CardNumberElement
							options={{
								showIcon: true
							}}
							onChange={handleCardChange}
						/>
					</div>
				</div>

				<div className="small-6 medium-3 cell">
					<label className="form-label">Card expiry</label>
					<div className="form-input" id="cardExpiry-wrapper">
						<CardExpiryElement
							onChange={handleCardChange}
						/>
					</div>
				</div>
				<div className="small-6 medium-3 cell">
					<label className="form-label">CVC</label>
					<div className="form-input" id="cardCvc-wrapper">
						<CardCvcElement
							onChange={handleCardChange}
						/>
					</div>
				</div>
				{terms ? (
				<Terms 
					supplier={terms.supplier} 
					booking={false} 
					setTerms={setTerms} 
					type="payment" 
				/>
				) : null }

			</div>
		</>
	)
}

const CardHeader = () => {
	return (
		<div className="grid-x grid-margin-x">
			<div className="booking-view__pay-with cell medium-12">
				<div className="grid-x grid-margin-x">
					<div className="small-4 medium-2 cell pay-with-col">
						<h5>Pay with</h5>
					</div>
					<div className="small-8 medium-10 cell cards-col">
						<ul>
							<li className="booking-view__pay-with--card"><span className="icon icon-card-visa"></span></li>
							<li className="booking-view__pay-with--card"><span className="icon icon-card-mastercard"></span></li>
							<li className="booking-view__pay-with--card"><span className="icon icon-card-jcb"></span></li>
							<li className="booking-view__pay-with--card"><span className="icon icon-card-discover"></span></li>
							<li className="booking-view__pay-with--card"><span className="icon icon-card-diners"></span></li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	)
}

export default CardForm
