import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format';
import PaymentForm from '../../../shared/payment-form'

const Addons = ({ config, addons }) => {

	const [ items, setItems ] = useState(addons)
	const total = items.reduce((a, c) => a + c.quantity * c.price, 0);

	const increment = ( item, quantity ) => {

		const newQuantity = quantity.toString().length > 0 && quantity != '' && Number.isInteger(quantity)  && quantity > 0 ? quantity : 0
		setItems(
			items.map((x) =>
				x.id === item.id ? { ...x, quantity: parseInt( newQuantity ) } : x
			)
		);

	}

	const addFixedPrice = ( item ) => {


		setItems(
			items.map((x) =>
				x.id === item.id ? { ...x, quantity: x.quantity == 1 ? 0 : 1 } : x
			)
		);

	}


	return (
		<div className="grid-x grid-margin-x">
			<div className="small-12 cell">
				<div className="panel customer-action-callout clearfix">
					<h3 className="highlight complete addons"><span className="icon-tick"></span>Would you like to add a little something extra?</h3>
					<div className="customer-action-callout--content">
						<div className="header-underlined show-for-large">
							<div className="grid-x grid-padding-x">
								<div className="small-8 medium-9">
									<h3>Addons</h3>
								</div>
								<div className="cell small-4 medium-3 text-right">
									<h3>Quantity</h3>
								</div>
							</div>
						</div>
						{items.map( item =>
							<div className="grid-x addon-row" key={`addon-` + item.id}>
								<div className="medium-9 cell">
									<div className="addon-row__name"><b>{item.name}</b></div>
									<div className="addon-row__description">{item.description}</div>
									<div className="addon-row__price">
										&pound;
										<NumberFormat
											itemProp="price"
											content={item.price}
											value={item.price}
											displayType="text"
											thousandSeparator={true}
											prefix=""
											decimalScale={2}
											fixedDecimalScale={true}
										/>
										<span className="label">{item.fixed_price ? ' Per group' : ' Per person'}</span>
									</div>
								</div>
								<div className="medium-3 cell text-right">
									{item.fixed_price ? (
										<button className={item.quantity > 0 ? 'button expanded deselect' : 'button expanded select' } onClick={(e) => addFixedPrice( item ) }>{item.quantity > 0 ? 'Remove' : 'Add' }</button>
									) : (
									<div className={item.quantity > 0 ? 'increment-box addons deselect' : 'increment-box addons select' }>
										<span className="increment-box__selected text-center">{item.quantity > 0 ? 'Added' : 'Add' }</span>
										<div className="increment-holder">
											<img src="/images/svg/minus-icon.svg" width="20" alt="minus icon" onClick={() => increment( item, item.quantity - 1)} />
		  									<input
		  										type="text"
		  										name="quantity"
		  										placeholder="0"
		  										value={item.quantity}
		  										onChange={(e) => {
		  											setItems(
		  												items.map((x) =>
		  													x.id === item.id ? { ...x, quantity: parseInt( e.target.value ) || '' } : x
		  												)
		  											);
		  										}}
		  									/>
		                  <img src="/images/svg/plus-icon.svg" width="20" alt="plus icon"  onClick={() => increment( item, item.quantity + 1)}/>
										</div>
									</div>
									)}
                </div>
							</div>
						)}

						<div className="addon-total">
							<div className="final-addon-total">Total:</div>
							<NumberFormat
								itemProp="total"
								content={total}
								value={total}
								displayType="text"
								thousandSeparator={true}
								prefix="&pound;"
								decimalScale={2}
								fixedDecimalScale={true}
							/>
						</div>
						{ total > 0 &&
						<PaymentForm
							token={config.token}
							auth={config.auth}
							config={config.payment}
							shortForm={false}
							additionalFields={null}
							additionalFormData={() => {
								return []
							}}
							getPaymentData={( cardFormData ) => {
								const result = items.reduce((results, item) => {
									if( item.quantity > 0 ) {
										results.push({ id: item.id, quantity: item.quantity })
									}
									return results
								}, [] )

								return {
									addons: result,
									total: total,
									firstname: cardFormData.cardFirstname.value,
									lastname: cardFormData.cardLastname.value,
									postcode: cardFormData.cardPostcode.value
								}
							}}
							raiseErrors={false}
							sendErrors={null}
							terms={false}
							tracking={false}
						/>
						}
					</div>
				</div>
			</div>
		</div>
	)

}

export default Addons
