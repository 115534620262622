import React, { useState } from 'react';
import ReactModal from 'react-modal';

const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)'
  },
  	content : {
  	padding				  			: '0',
		width				  				: '940px',
		maxWidth			  			: '940px',
		height				  			: 'auto',
    top                   : '50%',
    left                  : '50%',
    right                 : 'auto',
    bottom                : 'auto',
    marginRight           : '-50%',
    transform             : 'translate(-50%, -50%)'
  }
};

ReactModal.setAppElement('body');

const TermsWarningPopUp = ({ violation, setViolation, handleSubmit }) => {

	const handleContinue = (e) => {
		setViolation( false )
		handleSubmit(e, true)
	}
	
	const messageText = ( violation ) => {
		switch( violation.violations ) {
		case 'brochure':
			return(
				<p>A friendly reminder: As per our Terms of Business, we ask that you refrain from sharing marketing brochures or direct contact details with customers until they have confirmed their booking. If you feel there are images or details missing from your Book a Party listing, please let us know and we’ll happily action any updates as a priority. Thank you for your cooperation and understanding.</p>	
			)
			break;
		case 'vat':
			return(
				<p>Please note that Book a Party act only as a disclosed agent, therefore any VAT invoices must be provided by the experience provider if requested.</p>
			)
			break;
		default:
			return(
				<p>You are receiving this message because you have entered a <b>{violation.violations}</b>.Please note, if you are providing this information in order for the customer to contact you directly this goes against the terms of business that you have agreed to. All communication must be kept on the message board. </p>
			)
		}
	}
	return (
		<div>
			<ReactModal isOpen={violation ? true : false} style={ModalStyles}>
			<div className="bookmark__modal--container">
						{violation.supplier == true ?
							<div>
								<h3>Warning</h3>
								
								{messageText(violation)}
								<p>
								If you would like to proceed with sending your message hit Send anyway however, 
								please be aware a copy of your message will go to our auditing team at Book a Party.
								</p>
							</div>
							:
							<div>
								<h3>Warning</h3>
								<p>
								Hi {violation.name}, you are receiving this message because you have entered a <b>{violation.violations}</b>. 
								We strongly recommend that you keep all communication on the platform in order to access all booking information in one place.
								The message board is the easiest and fastest way to gather information from your experience provider.
								</p>
								<p>
								If you would like to send your message please press Send anyway. If you would like to edit your message please press edit.
								</p>
							</div>
						}
						<div className="grid-x grid-margin-x">
								<div className="cell medium-4 small-12">
									<button type="button" name="commit" className="button primary expanded" onClick={() => setViolation(false)}>
										Edit message
									</button>
								</div>
								<div className="cell medium-4 small-12">
									<button type="button" name="commit" className="button plain border expanded" onClick={handleContinue}>
										Send anyway
									</button>
								</div>
						</div>
						<button className="bookmark__modal--close-button" onClick={() => setViolation(false)} ><span className="icon icon-cross"></span></button>
					</div>
			</ReactModal>
		</div>
	)
}

export default TermsWarningPopUp