import React, { useState } from 'react'
import {loadStripe} from '@stripe/stripe-js';
import {Elements} from '@stripe/react-stripe-js';

//add this to fix ie11 fetch problems
import "isomorphic-fetch"

//our components
import CardPayment from './card-payment'

const stripePromise = loadStripe( STRIPE_KEY );

const PaymentForm = ({ token, auth, config, shortForm, additionalFields, additionalFormData, getPaymentData, raiseErrors, sendErrors, terms, tracking}) => {

	const [error, setError] = useState(false);

	return (

		<div id="payment-form" className="payment-form">

			{error
				? <div className="pay_error"> <span className="icon-cross"></span> {error}</div>
				: null
			}

			{additionalFields}

			<h3>Payment Information</h3>
			<Elements stripe={stripePromise}>
				<CardPayment
					token={token}
					auth={auth}
					config={config}
					shortForm={shortForm}
					handleError={( error ) => {
						if( raiseErrors ) {
							sendErrors( error )
						} else {
							setError( error )
						}
					}}
					additionalFormData={additionalFormData}
					getPaymentData={getPaymentData}
					terms={terms}
					tracking={tracking}
				/>
			</Elements>

			{
				config.mode == 'test' ?
				<div style={{color: 'red'}}><b>PAYMENTS ARE IN TEST MODE</b></div>
				: null
			}

		</div>
	)
}

export default PaymentForm
