import React, { useState, useEffect, useRef, useMemo } from "react"
import List from './list'
import Filters from './filters'
import Selector from './selector'
import Advert from 'shared/advert.jsx'
import EnquiryForm from './enquiry-form'
import ReactModal from 'react-modal';
import axios from 'axios'
import 'loader-blocks.gif';

ReactModal.setAppElement('body');

const ModalStyles = {
  overlay: {
	  backgroundColor: 'rgba(0, 0, 0, 0.75)',
	  zIndex: '1000'
  },
  content : {
	padding				  : '1rem',
	width				  : 'auto',
	maxWidth			  : '800px',
	height				  : 'auto',
	maxHeight             : '90%',
	top                   : '50%',
	left                  : '50%',
	right                 : 'auto',
	bottom                : 'auto',
	marginRight           : '-50%',
	overflow			  : 'unset',
	transform             : 'translate(-50%, -50%)'
  }
};


const Grid = ({ config, filters, results }) => {

	const [ layout, setLayout ] = useState('grid')
	const [ type, setType ] = useState( config.type )
	const [ loading, setLoading ] = useState( false )
	const [ searchResults, setSearchResults ] = useState( results )
	
	let originalPriceRange = false
	if( results.price != undefined ) {
		originalPriceRange = results.price
	}
	
	let defaultFilters = {}
	{Object.keys(filters).map(( filterKey ) => {
		const filter = filters[filterKey]
		if( filter.default_selection !== undefined && filter.default_selection !== null ) {
			defaultFilters[filterKey] = filter.default_selection.id
		}
	})}
	
	
	//POPUP ON SCROLL
	const scrollToRef = useRef()
	
	const options = {
		root: null,
		rootMargin: "0px",
		threshold: 1.0
	}
	
	const [popupForm, setPopupForm ] = useState( false )
	const [hasShownpopupForm, setHasShownPopupForm ] = useState( false )
	const hasShownpopupFormPreviously = () => localStorage.getItem('dateForm') || false;
	
	useEffect(() => {
		if( !config.formConfig.hasData && !hasShownpopupFormPreviously() && !hasShownpopupForm ) {
			const observer = new IntersectionObserver( (entries) => {
				const [ entry ] = entries
				if(  entry.isIntersecting ) {
					setPopupForm(true)
					setHasShownPopupForm(true)
					localStorage.setItem('dateForm', true);
					observer.unobserve( scrollToRef.current )
				}
			}, options )
			if( scrollToRef.current ) {
				observer.observe( scrollToRef.current )
			}
			return () => {
				if( scrollToRef.current ) {
					observer.unobserve( scrollToRef.current )
				}	
			}
		}
	}, [scrollToRef, options]);
	
	
	
	const [ payload, setPayload ] = useState({
		 type: config.type,
		 path: config.path,
		 order: 1,
		 filters: defaultFilters
	})
		
	const mounted = React.useRef(false);
			
		
	useEffect(() => {

		if( mounted.current ) {
			const unmount = loadData();
			return () => unmount && unmount();
		} else {
			mounted.current = true;
		}
		
	}, [payload]);

	const changeOrder = ( order ) => {
		if( order !== parseInt( searchResults.order )) {
			setPayload( { ...payload, order: order } )
		}
	}
	
	const runFilters = ( sentFilters ) => {
				
		const filterValues = {}
		const tags = []

		Object.keys(sentFilters).forEach( filter => {
				
			const definition = filters[filter]
			
			if( definition.ident == 'price' ) {
				filterValues[definition.ident] = sentFilters[filter] !== undefined ? sentFilters[filter] : null
			} else {
				 if( definition.config.multi ) {
				 	 const values = []
				 	 if( sentFilters[filter] !== undefined || sentFilters[filter] !== null ) {
				 	 	 sentFilters[filter].forEach( item => {
				 	 	 	if( !item.id !== undefined ) {
				 	 	 		values.push( item.id )
				 	 	 	}
				 	 	 })
				 	 	 filterValues[definition.ident] = values.length > 0 ? values : null
				 	 } else {
				 	 	 filterValues[definition.ident] = null
				 	 }
				 } else {
				 	 filterValues[definition.ident] = sentFilters[filter] !== null && sentFilters[filter].id !== undefined  ? sentFilters[filter].id : null
				 }
			}
			
			if( definition.config.tag && filterValues[definition.ident] !== null ) {
				if( definition.config.multi ) {
					//TODO: multi definition for tags
				} else {
					tags.push(filterValues[definition.ident])
				}
			}
			
		})
		
		//if( tags.length ) {
		filterValues['tags'] = tags

		setPayload({ ...payload, filters: filterValues })
	}

	const switchLayout = ( e, layout ) => {
		e.preventDefault();
		e.stopPropagation();
		setLayout(layout);
	}
	
	const loadData = () => {
		setLoading( true );
		
		axios.post( '/products/list2', {
			authenticity_token: config.token,
			search: payload
		}).then( response => {
			setLoading( false );
			setSearchResults( response.data );

		}).catch(err => {
			setLoading( false );
			console.log(err);
		});
	}
	
    return (
      <React.Fragment>

		{config.selector &&
			<Selector selector={config.selector} />
		}

		<EnquiryForm config={config.formConfig} type="green" hideLocation={false} />
		
		{popupForm && <a onClick={() => setPopupForm( false )}>POPUP</a>}
		
		<div className="grid-x grid-padding-x">

			<div className="cell small-12 large-3">
				
			
				<Filters 
					filterDefinitions={filters}
					originalPriceRange={originalPriceRange}
					switchLayout={switchLayout}
					order={searchResults.order}
					changeOrder={changeOrder}
					runFilters={runFilters}
				/>

				{config.advert ? (
					<div className="show-for-large mg-v-lrg">
						<Advert />
					</div>
				) : null}
				
			</div>

			<div className="cell small-12 large-9">
				<>
				{loading &&
					<div className="loader small-12 cell text-center">
						<img src={require(`loader-blocks.gif`)}/>
					</div>
				}
				{!loading &&

					<List 
						layout={layout}
						config={config}
						results={searchResults}
						order={searchResults.order}
						changeOrder={changeOrder}
						ref={scrollToRef}
					/>
				}
				</>
			</div>
		</div>
		
		<ReactModal isOpen={popupForm} contentLabel="Date Search" style={ModalStyles}>
			<button onClick={() => setPopupForm( false )} className="close-button" aria-label="Close modal">
				<span aria-hidden="true">×</span>
			</button>
			<h2>Event details</h2>
			<p>Make sure you add the details for your event to see what's available!</p>
			<EnquiryForm 
				config={config.formConfig}
				type="plain"
				hideLocation={true}
			/>
		</ReactModal>

	  </React.Fragment>
    );
}

const Loader = props => (
	<div className="loader small-12 cell text-center">
		<img src={require(`loader-blocks.gif`)}/>
	</div>
);

export default Grid
